import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "company": "Huawei",
  "title": "Linux Kernel / Application Developer Intern",
  "time": "Summer 2021",
  "index": 3,
  "type": "position",
  "hidden": false,
  "splash": "Developing modern lightweight cryptographic algorithms improving data security in next-gen IoT devices"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1><a parentName="h1" {...{
        "href": "https://www.huawei.com/"
      }}>{`Huawei Technologies`}</a></h1>
    <p><strong parentName="p">{`Date Range:`}</strong>{` May - Aug 2021`}</p>
    <p><strong parentName="p">{`Key Technologies:`}</strong>{` `}<inlineCode parentName="p">{`C/C++`}</inlineCode>{` `}<inlineCode parentName="p">{`pthreads`}</inlineCode>{` `}<inlineCode parentName="p">{`Bash`}</inlineCode></p>
    <p><strong parentName="p">{`Location:`}</strong>{` Ontario, Canada`}</p>
    <p><strong parentName="p">{`Team:`}</strong>{` Lightweight Cryptography`}</p>
    <hr></hr>
    <p>{`In my second term at Huawei I worked in our `}<em parentName="p">{`Data Security & Privacy Lab`}</em>{` on the `}<em parentName="p">{`Lightweight Cryptography (LWC)`}</em>{` team. My team's goal was to produce a lightweight cryptography library for usage across the company and by partners.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Lightweight cryptography is an area of active research concerned with developing a collection of algorithms and methodologies specifically targeted at constrained execution environments. Different LWC algorithms optimize for different constraints but typically the constraints considered include:`}</p>
      <ol parentName="blockquote">
        <li parentName="ol"><strong parentName="li">{`Cost`}</strong>{` - Custom hardware for hardware acceleration is not feasible`}</li>
        <li parentName="ol"><strong parentName="li">{`Power Consumption`}</strong>{` - Algorithms must consume as little energy as possible so that they can continue to function on IoT devices like credit cards`}</li>
        <li parentName="ol"><strong parentName="li">{`Security`}</strong>{` - Algorithms must provide acceptable security against modern attacks`}</li>
        <li parentName="ol"><strong parentName="li">{`Overhead`}</strong>{` - For example reduce authentication code tag length to reduce network overhead`}</li>
      </ol>
    </blockquote>
    <p>{`Over the course of the term I implemented 5 different algorithms in our library including:`}</p>
    <ol>
      <li parentName="ol"><inlineCode parentName="li">{`SKINNY`}</inlineCode>{` - Lightweight tweakable block cipher`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`SKINNY-Hash`}</inlineCode>{` - A cryptographic hash function based on the `}<inlineCode parentName="li">{`SKINNY`}</inlineCode>{` Block cipher`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`Romulus`}</inlineCode>{` - An AEAD (Authenticated Encryption with Additional Data) scheme based on `}<inlineCode parentName="li">{`SKINNY`}</inlineCode></li>
      <li parentName="ol"><inlineCode parentName="li">{`LightMAC`}</inlineCode>{` - A lightweight algorithm for message authentication configurable to use any block cipher. LightMAC reduces authentication tag length by performing `}<em parentName="li">{`tag truncation`}</em>{` sacrificing exponential security loss for linear tag size reduction.`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`ProMAC`}</inlineCode>{` - A bleeding edge lightweight MAC algorithm for authenticating packet streams. ProMAC uses a rolling internal state to maintain high `}<em parentName="li">{`eventual security`}</em>{` for packets by calculating authentication codes using material from previous packets. This approach allows individual tags to remain short without sacrificing security. At time of writing no open source implementations of `}<inlineCode parentName="li">{`ProMAC`}</inlineCode>{` exist.`}</li>
    </ol>
    <p>{`Over the course of the term I also assisted in the development of a `}<strong parentName="p"><em parentName="strong">{`Memory Integrity Protection`}</em></strong>{` scheme essentially aiming to encrypt and authenticate all accesses to RAM preventing attackers with physical device access from compromising the device.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      